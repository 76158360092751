export class API {
	constructor(logger) {
		this.logger = logger;
		this.base_url = "http://127.0.0.1:5000";
		if (window.location.hostname.indexOf("gusano") >= 0) {
			this.base_url = "https://gusano.org/prompt_api";
		}
	}

	init(
		chat_model,
		chat_role,
		prompt_role,
		prompt_addendum,
		sd_model,
		sd_generation_steps,
		sd_inpainting_steps
	) {
		this.chat_model = chat_model;
		this.chat_role = chat_role;
		this.prompt_role = prompt_role;
		this.prompt_addendum = prompt_addendum;
		this.sd_model = sd_model;
		this.sd_generation_steps = sd_generation_steps;
		this.sd_inpainting_steps = sd_inpainting_steps;
	}

	async apiCall(url, content, content_type = "application/octet-stream") {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": content_type,
			},
			body: content, // This is your file object
		});

		if (!response.ok) {
			const message = `An error has occured: ${response.status}`;
			throw new Error(message);
		}

		return response;
	}

	/*
	async audio2image(mode, audio_data, audio_format) {
		const url = `${this.base_url}/${mode}2image/${audio_format}`;

		const response = await this.apiCall(url, audio_data, "application/octet-stream");

		const imageBlob = await response.blob();

		return URL.createObjectURL(imageBlob);
	}
	*/

	async speech_to_text(audio_data, audio_format) {
		const url = `${this.base_url}/speech_to_text/${audio_format}`;

		const response = await this.apiCall(url, audio_data, "application/octet-stream");

		return await response.json();
	}

	async text_to_image(prompt, filename, format = "image", width = -1, height = -1) {
		const url = `${this.base_url}/text_to_image`;

		if (width === -1 || height === -1) {
			if (this.sd_model === "stable-diffusion-512-v2-1") {
				width = 1024;
				height = 512;
			} else {
				width = 1344;
				height = 768;
			}
		}

		const response = await this.apiCall(
			url,
			JSON.stringify({
				prompt: prompt,
				filename: filename,
				format: format,
				width: width,
				height: height,
				model: this.sd_model,
				generation_steps: this.sd_generation_steps,
				inpainting_steps: this.sd_inpainting_steps,
			}),
			"application/json"
		);

		if (format === "image") {
			return await response.blob();
		} else {
			return await response.text();
		}
	}

	async upscale_image(filename, mode, format = "image") {
		const url = `${this.base_url}/upscale_image/${filename}/${mode}/${format}`;

		const response = await this.apiCall(url, "", "text/plain");

		if (format === "image") {
			return await response.blob();
		} else {
			return await response.text();
		}
	}

	async chat(history, message) {
		const url = `${this.base_url}/chat`;

		const response = await this.apiCall(
			url,
			JSON.stringify({
				model: this.chat_model,
				role: this.chat_role,
				history: history,
				message: message,
			}),
			"application/json"
		);

		return await response.json();
	}

	async generate_prompt(message) {
		const url = `${this.base_url}/generate_prompt`;

		const response = await this.apiCall(
			url,
			JSON.stringify({
				model: this.chat_model,
				role: this.prompt_role,
				message: message,
			}),
			"application/json"
		);

		const json = await response.json();
		return json.response + this.prompt_addendum;
	}

	async tts(text) {
		const url = `${this.base_url}/tts/`;

		const response = await this.apiCall(url, text, "application/json");

		return await response.blob();
	}

	get_full_image_url(url) {
		return `${this.base_url}/${url}`;
	}
}
