const define_emit_event_component = () => {
	// eslint-disable-next-line no-undef
	AFRAME.registerComponent("emit-event-on-hover", {
		schema: {
			event: { default: "" },
		},

		init: function () {
			this.el.addEventListener("mouseenter", () => {
				this.el.emit(this.data.event, { action: "enter" });
			});

			this.el.addEventListener("mouseleave", () => {
				this.el.emit(this.data.event, { action: "leave" });
			});

			this.el.addEventListener("click", () => {
				this.el.emit(this.data.event, { action: "click" });
			});
		},
	});
};

export default define_emit_event_component;
