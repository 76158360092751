const define_button_component = () => {
	// eslint-disable-next-line no-undef
	AFRAME.registerComponent("button", {
		schema: {},
		// eslint-disable-next-line no-undef
		initialScale: new THREE.Vector3(1, 1, 1),

		init: function () {
			this.initialScale = this.el.object3D.scale.clone();

			this.el.addEventListener("mouseenter", () => {
				this.el.object3D.scale.multiplyScalar(1.2);
			});

			this.el.addEventListener("mouseleave", () => {
				this.el.object3D.scale.copy(this.initialScale);
			});

			this.el.addEventListener("click", () => {
				// Animar
			});
		},
	});
};

export default define_button_component;
