// noinspection SpellCheckingInspection

import { API } from "./api";
import { Logger } from "./log";
import { AudioRecorder } from "./audio";
import { textHash } from "./utils";

export class Controller {
	constructor() {
		this.logger = new Logger(document.getElementById("log"));
		this.api = new API(this.logger);
		this.audioRecorder = new AudioRecorder(this.receive_audio_and_upload.bind(this));
		this.audioRecorder.requestAccess();
		this.discard = null;

		this.history = [];
		// noinspection CssInvalidHtmlTagReference
		this.scene = document.querySelector("a-scene");

		this.number_of_skies = 0;
		this.skies = [];
		this.skies_el = document.getElementById("skies");
		this.skies_upscaled = [];
		this.skies_upscaled_el = document.getElementById("skies_upscaled");

		// Elements de la interfície
		const start = document.getElementById("start");
		// noinspection JSCheckFunctionSignatures
		start.parentNode.removeChild(start);

		this.loadingAnimation = document.getElementById("loading");

		this.recordButton = document.getElementById("record");
		this.discardButton = document.getElementById("discard");
		this.sendButton = document.getElementById("send");

		// Descarreguem la configuració i inicialitzem el procés
		let config_folder = "config";
		if (typeof prompt_config !== "undefined") {
			// eslint-disable-next-line no-undef
			config_folder = prompt_config;
		}
		fetch(`./${config_folder}/config_js.php`)
			.then((response) => response.json())
			.then(async (configs) => {
				console.log("Init");
				this.api.init(
					configs["chat_model"],
					configs["chat_system"],
					configs["prompt_system"],
					configs["prompt_addendum"],
					configs["sd_model"],
					configs["sd_generation_steps"],
					configs["sd_inpainting_steps"]
				);

				this.message_start = configs["message_start"];
				this.message_end = configs["message_end"];
				this.max_interactions = configs["max_interactions"];

				// noinspection ES6MissingAwait
				this.generate_image(configs["chat_user"]);
				this.loadingAnimation.setAttribute("visible", "true");

				this.api.tts(this.message_start).then((audioBlob) => {
					this.play_audio(audioBlob).then(async () => {
						// noinspection ES6MissingAwait
						this.generate_response(configs["chat_user"]);
					});
				});
			});
	}

	// Callback de la gravació d'àudio
	async receive_audio_and_upload(recordedChunks) {
		this.logger.log("Audio data available");
		if (this.discard) {
			this.logger.log("-> Audio data discarded");
			return;
		}
		await this.upload_audio(new Blob(recordedChunks), "webm");
	}

	// Comença a gravar l'àudio
	async start_recording() {
		this.logger.log("Starting recording");
		// noinspection JSCheckFunctionSignatures
		this.recordButton.emit("recordStart", null, false);
		this._set_recording_buttons_visibility(true, true);
		this.audioRecorder.start();
	}

	// Para de gravar l'àudio
	async send_recording() {
		this.logger.log("Sending recording");
		this.discard = false;
		// noinspection JSCheckFunctionSignatures
		this.recordButton.emit("recordEnd", null, false);
		this._set_recording_buttons_visibility(false, false);
		this.audioRecorder.stop();
	}

	// Para de gravar l'àudio
	async discard_recording() {
		this.logger.log("Discarding recording");
		this.discard = true;
		// noinspection JSCheckFunctionSignatures
		this.recordButton.emit("recordEnd", null, false);
		this._set_recording_buttons_visibility(true, false);
		this.audioRecorder.stop();
	}

	// Amaga o mostra els botons de gravació
	_set_recording_buttons_visibility(record, others) {
		this.recordButton.setAttribute("visible", record);
		this.discardButton.setAttribute("visible", others);
		this.sendButton.setAttribute("visible", others);
	}

	// Genera una resposta al missatge de l'usuari amb GPT i la converteix a audio i imatge
	async generate_response(message) {
		const message_response = await this.api.chat(this.history, message);
		console.log("message_response", message_response);

		this.history = message_response.history;

		this.api.tts(message_response.response).then((audioBlob) => {
			this.play_audio(audioBlob).then(() => {
				this._set_recording_buttons_visibility(true, false);
			});
		});
	}

	get_conversation(message) {
		const messages = this.history.slice(1);
		const conversation = messages.reduce(
			(accumulator, currentValue) => `${accumulator}- ${currentValue.content}\n`,
			""
		);
		return `${conversation}- ${message}\n`;
	}

	// Genera una imatge a partir d'un missatge, fa upscale i la mostra
	async generate_image(message) {
		const conversation = this.get_conversation(message);

		this.api.generate_prompt(conversation).then(async (prompt) => {
			console.log("chatgpt prompt_response", prompt);

			const index = this.number_of_skies++;

			const filename = await textHash(prompt);
			this.api.text_to_image(prompt, filename, "url").then((imageURL) => {
				const sky = document.createElement("a-entity");
				sky.setAttribute("psky", {
					index: index,
					size: "small",
					src: this.api.get_full_image_url(imageURL),
				});
				this.skies_el.appendChild(sky);
				this.skies.push(sky);

				const fade_out_all_skies = () => {
					this.loadingAnimation.setAttribute("visible", "false");

					// TODO: fer només aquells amb index més petit?
					while (this.skies.length > 2) {
						const old_sky = this.skies.shift();
						old_sky.emit("fadeOut");
					}
					while (this.skies_upscaled.length > 1) {
						const old_sky_upscaled = this.skies_upscaled.shift();
						old_sky_upscaled.emit("fadeOut");
					}
					this.scene.removeEventListener(
						`fade_in_start_small_${index}`,
						fade_out_all_skies
					);
				};
				this.scene.addEventListener(`fade_in_start_small_${index}`, fade_out_all_skies);

				// noinspection JSCheckFunctionSignatures
				this.logger.log("-> Sending image for upscaling");
				this.api.upscale_image(filename, "gen", "url").then((upscaledImageURL) => {
					// TODO: mirar si no s'ha de fer perquè ja en tenim una nova de petita
					const sky_upscaled = document.createElement("a-entity");
					// TODO: fer-ho mś tard per evitar el blip
					sky.emit("fadeOut");
					sky_upscaled.setAttribute("psky", {
						index: index,
						size: "big",
						src: this.api.get_full_image_url(upscaledImageURL),
					});
					this.skies_upscaled_el.appendChild(sky_upscaled);
					this.skies_upscaled.push(sky_upscaled);

					const fade_out_upscaled_skies = () => {
						// TODO: fer només aquells amb index més petit?
						while (this.skies_upscaled.length > 2) {
							const old_sky_upscaled = this.skies_upscaled.shift();
							old_sky_upscaled.emit("fadeOut");
						}
						this.scene.removeEventListener(
							`fade_in_start_big_${index}`,
							fade_out_upscaled_skies
						);
					};
					this.scene.addEventListener(
						`fade_in_start_big_${index}`,
						fade_out_upscaled_skies
					);

					while (this.skies_upscaled.length > 1) {
						const old_sky_upscaled = this.skies_upscaled.shift();
						old_sky_upscaled.emit("fadeOut");
					}
				});
			});
		});
	}

	// Envia l'audio de l'usuari per obtenir una transcripció i amb el resultat genera una resposta
	async upload_audio(audio_data, audio_format = "mp3") {
		let user_message, filename;
		if (audio_data !== null) {
			this.logger.log("-> Uploading audio for transcription");
			// JS Object Destructuring into existing variables ()
			// https://flaviocopes.com/javascript-destructure-object-to-existing-variable/
			({ text: user_message, filename } = await this.api.speech_to_text(
				audio_data,
				audio_format
			));
			this.logger.log(`<- Text to speech result: ${user_message} (filename: ${filename})`);
		} else {
			user_message = "A futuristic castle";
		}

		if ((this.history.length - 3) / 2 < this.max_interactions) {
			// noinspection ES6MissingAwait
			this.generate_response(user_message);
			// noinspection ES6MissingAwait
			this.generate_image(user_message);
		} else {
			const conversation = this.get_conversation(user_message);
			const prompt = `${this.message_end}\n${conversation}`;
			this.api.chat([], prompt).then((response) => {
				this.api.tts(response.response).then((audioBlob) => {
					this.play_audio(audioBlob).then(async () => {
						console.log("finished");
					});
				});
			});
		}
	}

	// Reprodueix un audio
	async play_audio(audioBlob) {
		return new Promise((resolve) => {
			const audioURL = window.URL.createObjectURL(audioBlob);
			const audioEl = document.getElementById("audio");
			audioEl.src = audioURL;
			audioEl.play();

			const end_conversation = async () => {
				audioEl.removeEventListener("ended", end_conversation);
				resolve();
			};

			audioEl.addEventListener("ended", end_conversation);
		});
	}
}
