export class AudioRecorder {
	constructor(stopCallback) {
		this.stopCallback = stopCallback;

		this.options = { mimeType: "audio/webm" };
		this.recordedChunks = [];
		this.mediaRecorder = null;
	}

	requestAccess() {
		navigator.mediaDevices
			.getUserMedia({ audio: true, video: false })
			.then(this._handleSuccess.bind(this));
	}

	_handleSuccess(stream) {
		this.mediaRecorder = new MediaRecorder(stream, this.options);

		this.mediaRecorder.addEventListener("dataavailable", (e) => {
			if (e.data.size > 0) this.recordedChunks.push(e.data);
		});

		this.mediaRecorder.addEventListener("stop", () => {
			this.stopCallback(this.recordedChunks);
		});
	}

	start() {
		this.recordedChunks = [];
		this.mediaRecorder.start();
	}

	stop() {
		this.mediaRecorder.stop();
	}
}
