const define_psky_component = () => {
	// eslint-disable-next-line no-undef
	AFRAME.registerComponent("psky", {
		schema: {
			index: { type: "int", default: 0 },
			size: { type: "string", default: "small" },
			src: { type: "string", default: "" },
			fadeDelay: { type: "int", default: 500 },
		},
		sky: null,

		init: function () {
			// noinspection CssInvalidHtmlTagReference
			this.scene = document.querySelector("a-scene");

			this.sky = document.createElement("a-sky");
			if (this.data.size === "small") {
				this.sky.setAttribute(
					"material",
					`opacity: 0; shader: flat; src: ${this.data.src}`
				);
				this.sky.setAttribute("transparent", "true");
			} else {
				this.sky.setAttribute(
					"material",
					`opacity: 0; shader: flat; src: ${this.data.src}`
				);
			}
			this.sky.setAttribute(
				"animation__fadein",
				"property: components.material.material.opacity; isRawProperty: true; to: 1; dur: 2000; easing: linear; startEvents: fadeIn"
			);
			this.sky.setAttribute(
				"animation__fadeout",
				"property: components.material.material.opacity; isRawProperty: true; to: 0; dur: 3000; easing: linear; startEvents: fadeOut"
			);
			this.sky.setAttribute("radius", this.data.size === "big" ? 600 : 500 + this.data.index);
			this.el.appendChild(this.sky);

			this._add_event_listerners();
		},

		_add_event_listerners() {
			const materialTextureLoaded = () => {
				console.log("materialtextureloaded", this.data.size);
				this.el.removeEventListener("materialtextureloaded", materialTextureLoaded);
				setTimeout(() => {
					console.log("Sky fadeIn", this.data.size);
					this.sky.emit("fadeIn");
					this.scene.emit(`fade_in_start_${this.data.size}_${this.data.index}`);
				}, this.fadeDelay);
			};
			this.sky.addEventListener("materialtextureloaded", materialTextureLoaded);

			const fadeOut = () => {
				console.log("fadeout received", this.data.size);
				this.el.removeEventListener("fadeOut", fadeOut);
				this.sky.emit("fadeOut");
			};
			this.el.addEventListener("fadeOut", fadeOut);

			this.sky.addEventListener("animationcomplete__fadeout", () => {
				console.log("animationcomplete__fadeout", this.data.size);
				this.sky.setAttribute("visible", "false");
				this.el.removeChild(this.sky);
				this.sky = null;
			});
		},
	});
};

export default define_psky_component;
