export class Logger {
	constructor(div) {
		this.div = div;
	}

	log(message) {
		if (this.div !== null) {
			this.div.append(this.createDiv(message));
		}
		console.log(message);
	}

	createDiv(text) {
		const newDiv = document.createElement("div");
		const newContent = document.createTextNode(text);
		newDiv.appendChild(newContent);
		return newDiv;
	}
}
