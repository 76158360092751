import { Controller } from "../lib/controller";

const define_prompt_controller_component = () => {
	// eslint-disable-next-line no-undef
	AFRAME.registerComponent("prompt-controller", {
		schema: {},
		recording: false,

		init: function () {
			this.controller = null;

			this.el.addEventListener("start", async (evt) => {
				if (this.controller || evt.detail.action !== "click") {
					return;
				}

				console.log("Start!");
				this.controller = new Controller();
			});

			this.el.addEventListener("record-start", async (evt) => {
				if (this.recording || evt.detail.action !== "click") {
					return;
				}

				console.log("record-start", evt.detail.action);
				await this.controller.start_recording();
				this.recording = true;
			});

			this.el.addEventListener("record-discard", async (evt) => {
				if (!this.recording || evt.detail.action !== "click") {
					return;
				}

				console.log("record-discard", evt.detail.action);
				await this.controller.discard_recording();
				this.recording = false;
			});

			this.el.addEventListener("record-send", async (evt) => {
				if (!this.recording || evt.detail.action !== "click") {
					return;
				}

				console.log("record-send", evt.detail.action);
				await this.controller.send_recording();
				this.recording = false;
			});
		},
	});
};

export default define_prompt_controller_component;
